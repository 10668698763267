<template>
    <fieldset>
        <validation-observer
            ref="VFormDokumen"
        >
            <div class="row">
                <div class="col-md-12">
                    <div class="row">
                        <div class="col-md-6" v-for="(v,k) in isParent.$parent.ddpMR" :key="k">
                            <div class="wrap_doc_type">
                                <div class="opacity-0">{{k}}</div>
                                <div class="btn_delete_wrap">
                                    <button type="button" @click="removeRowDoc(k)" class="btn btn-outline-danger-100 text-danger btn-icon"><i class="icon-trash"></i></button>
                                </div>
                                <div class="row">
                                    <div class="col-md-6">
                                        <div class="form-group">
                                            <label>Jenis Dokumen <span class="text-danger">*</span></label>
                                            <v-select placeholder="Select Jenis Dokumen" v-model="isParent.$parent.ddpMR[k]['ddp_document']" :options="isParent.$parent.mrDoc" label="text" :clearable="true" :reduce="v=>v.value"></v-select>
                                            <VValidate 
                                                :name="'Jenis Dokumen '+(k+1)" 
                                                v-model="isParent.$parent.ddpMR[k]['ddp_document']" 
                                                :rules="{required:1}"
                                            />
                                        </div>
                                    </div>
                                    <div class="col-md-6">
                                        <div class="result_tab">
                                            <label>File NPWP <span class="text-danger">*</span></label>
                                            <Uploader v-model="isParent.$parent.ddpMR[k]['ddp_file']" isDocument type="npwp"/>     
                                            <VValidate 
                                                :name="'File NPWP '+(k+1)" 
                                                v-model="isParent.$parent.ddpMR[k]['ddp_file']" 
                                                :rules="{required:1}"
                                            />   
                                        </div>
                                    </div>
                                    <div class="col-12">
                                        <div>
                                            <label for="">Remarks</label>
                                            <textarea @input="isParent.$parent.removeMultiSpaceDebArr($event,'ddp_remarks','ddpMR',k)" @keyup="isParent.$parent.removeWildCharDeb" @keydown="isParent.$parent.removeWildCharDeb" v-model="isParent.$parent.ddpMR[k]['ddp_remarks']" name="" id="" rows="4" class="form-control" placeholder="e.g. Dokumen sudah terupdate"></textarea></div>
                                        <VValidate 
                                            :name="'Remarks '+(k+1)" 
                                            v-model="isParent.$parent.ddpMR[k]['ddp_remarks']" 
                                            :rules="{regex:/^[^\s]+(\s+[^\s]+)*$/, min:3, max: 60}"
                                        />
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div class="col-12">
                            <button type="button" @click="addRowDoc" class="btn btn-light alpha-purple text-purple-800"> <i class="icon-plus3"></i> Tambah Dokumen</button>
                        </div>
                    </div>
                </div>
            </div>
        </validation-observer>
    </fieldset>
</template>

<script>
    import GlobalVue from '@/libs/Global.vue'
    // import Gen from '@/libs/Gen.js'
    // import Datepicker from 'vuejs-datepicker'

    export default {
        extends: GlobalVue,
        props: ['clickedNext', 'currentStep'],
        // components:{ Datepicker },
        computed: {
            isParent() { return this.$parent.$parent.$parent.$parent }
        },
        data(){
            return {
                isNextStep : false
            }
        },
        methods: {
            addRow(){
                let payload = {
                    ddp_object_id: this.isParent.row.di_id||"add", 
                    dap_no_akta: "", 
                    dap_tgl_akta: null, 
                    dap_notaris: "",
                    dap_no_pengesahan: "",
                    dap_file: "",
                    dap_tanggal_pengesahan: null
                }
                
                this.isParent.$parent.dapMR.push(payload)
            },
            removeRow(k){
                this.isParent.$parent.dapMR.splice(k,1)      
            },

            addRowDoc(){
                let payload = {
                    ddp_object_id: this.isParent.row.di_id||"add", 
                    ddp_type: 'INDIVIDU',
                    ddp_document: "",
                    ddp_file: "",
                    ddp_remarks: ""
                }
                
                this.isParent.$parent.ddpMR.push(payload)
            },
            removeRowDoc(k){
                this.isParent.$parent.ddpMR.splice(k,1)      
            }
        },
    }
</script>