<template>
    <fieldset>
        <validation-observer
            ref="VFormStatus"
        >
            <div class="row">
                <div class="col-md-12">
                    <div class="heading_line">
                        <h4>Catatan Fasilitas</h4>
                    </div>
                </div>
                <div class="col-md-12">
                    <div class="box_upload_img mb-3">
                        <div class="form-group">
                            <label>Catatan</label>
                            <textarea @input="isParent.$parent.removeMultiSpaceDeb($event,'di_kelengkapan_data')" @keyup="isParent.$parent.removeWildCharDeb" @keydown="isParent.$parent.removeWildCharDeb" v-model="isParent.row.di_kelengkapan_data" rows="4" cols="3" class="form-control" placeholder="Isi catatan..."></textarea>
                          
                            <VValidate 
                                name="Catatan" 
                                v-model="isParent.row.di_kelengkapan_data" 
                                :rules="{regex:/^[^\s]+(\s+[^\s]+)*$/, min:3, max:256}"
                            />
                        </div>
                    </div>
                </div>
            </div>
        </validation-observer>
    </fieldset>
</template>

<script>
    import GlobalVue from '@/libs/Global.vue'

    export default {
        extends: GlobalVue,
        props: ['clickedNext', 'currentStep'],
        data(){
            return {
                isNextStep : false
            }
        },
        computed: {
            isParent() { return this.$parent.$parent.$parent.$parent }
        }
    }
</script>