<template>
<validation-observer
    v-slot="{ handleSubmit }" ref="form"
>
    <b-form @submit.prevent="handleSubmit(doSubmit())">
        <b-card no-body>
            <b-card-header>
                <b-row>
                    <b-col lg="8">
                    <h5 class="card-title">{{ $parent.isAdd?'Add':'Edit' }} {{ $parent.pageTitle }}</h5>
                    </b-col>
                </b-row>
            </b-card-header>
            <b-card-body class="p-0">
                <VStepTab :step.sync="stepTab" :last="debSteps.length" :config="stepValidate">
                    <template slot="link">
                        <template v-for="(v,k) in debSteps">
                            <VStepLink :step.sync="stepTab" :option="k + 1" :key="k">
                                {{ v.subtitle }}
                            </VStepLink>
                        </template>
                    </template>
                    <template slot="content">
                        <template v-for="(v,k) in debSteps">
                            <VStepContent :step.sync="stepTab" :option="k+1" :key="k">
                                <template slot="title">
                                    {{ v.subtitle }}
                                </template>
                                <template slot="fieldset">
                                    <div class="p-3">
                                        <InfoPribadi v-if="stepTab == 1" />
                                        <Dokumen v-if="stepTab == 2" />
                                        <Penjamin v-if="stepTab == 3" />
                                        <Rekening v-if="stepTab == 4" />
                                        <Status v-if="stepTab == 5" />
                                    </div>
                                </template>
                            </VStepContent>
                        </template>
                    </template>
                    <template slot="action">
                        <ul role="menu" aria-label="Pagination">
                            <li :class="(stepTab==1?'disabled':'')" v-bind:aria-disabled="(stepTab==1?true:false)" @click="doPrev"><a href="javascript:" :class="'btn btn-light '+(stepTab==1?'disabled':'')" role="menuitem"><i class="icon-arrow-left13 mr-2"></i> Previous</a></li>
                            <li v-if="stepTab!=stepLast" aria-hidden="false" aria-disabled="false" @click="doNext"><a href="javascript:" class="btn btn-primary" role="menuitem">Next <i class="icon-arrow-right14 ml-2"></i></a></li>
                            <li aria-hidden="false" aria-disabled="false"><button @click="doSubmit()"  type="button" class="btn btn-success" role="menuitem">Save <i class="icon-checkmark3 ml-2"></i></button></li>
                        </ul>
                    </template>
                </VStepTab>
            </b-card-body>
        </b-card>
    </b-form>
</validation-observer>

</template>

<script>
import InfoPribadi from './InfoPribadi.vue'
import Penjamin from './Penjamin.vue'
import Dokumen from './Dokumen.vue'
import Rekening from './Rekening.vue'
import Status from './Status.vue'
import Gen from '@/libs/Gen.js'

import VStepTab from '@/components/VStep/VStepTab.vue'
import VStepLink from '@/components/VStep/VStepLink.vue'
import VStepContent from '@/components/VStep/VStepContent.vue'

export default{
    components: {
        VStepTab, VStepLink, VStepContent,
        InfoPribadi, Penjamin, Dokumen, Rekening,
        Status
    },
    props:{
        row:Object,
        Config:Object
    },
    data(){
        return {
            stepTab: 1,
            stepLast: 5,
            debSteps: [
                {
                    icon: 'create',
                    name: 'informasi-pribadi',
                    subtitle: 'Informasi Pribadi',
                    component: InfoPribadi,
                    completed: false

                },
                {
                    icon: 'create',
                    name: 'dokumen',
                    subtitle: 'Dokumen',
                    component: Dokumen,
                    completed: false
                },
                {
                    icon: 'create',
                    name: 'penjamin-data',
                    subtitle: 'Penjamin Data',
                    component: Penjamin,
                    completed: false
                },                
                {
                    icon: 'create',
                    name: 'rekening',
                    subtitle: 'Rekening Bank & VA',
                    component: Rekening,
                    completed: false
                },
                {
                    icon: 'create',
                    name: 'status',
                    subtitle: 'Status',
                    component: Status,
                    completed: false
                }
            ],
            activeSteps:{}
        }
    },
    methods: {
        doPrev(){
            if(this.stepTab !== 1){
                this.stepTab = this.stepTab -1
                window.scrollTo(0,0)
            }
        },
        doNext(){
            if(this.stepTab < this.stepLast){
                if(this.stepTab == 1){
                    this.$children[0].$children[0].$children[5].$children[0].$refs['VFormInfo'].validate().then(success => {
                        if(success){
                            this.stepTab = this.stepTab + 1
                        }
                    })
                }else if(this.stepTab == 2){
                    this.$children[0].$children[0].$children[6].$children[0].$refs['VFormDokumen'].validate().then(success => {
                        if(success){
                            this.stepTab = this.stepTab + 1
                        }
                    })
                }else if(this.stepTab == 3){
                    this.$children[0].$children[0].$children[7].$children[0].$refs['VFormPenjamin'].validate().then(success => {
                        if(success){
                            this.stepTab = this.stepTab + 1
                        }
                    })
                }else if(this.stepTab == 4){
                    this.$children[0].$children[0].$children[8].$children[0].$refs['VFormRekening'].validate().then(success => {
                        if(success){
                            this.stepTab = this.stepTab + 1
                        }
                    })
                }else if(this.stepTab == 5){
                    this.$children[0].$children[0].$children[9].$children[0].$refs['VFormStatus'].validate().then(success => {
                        if(success){
                            this.stepTab = this.stepTab + 1
                        }
                    })
                }else{
                    console.log("no action")
                }

                window.scrollTo(0,0)
            }
        },
       
        doSubmit(){            
            let data = this.row
            data.type = this.$parent.isAdd?'add':'update'
            data.dpsData = this.$parent.dpsMR
            data.dkpData = this.$parent.dkpMR
            data.dapData = this.$parent.dapMR
            data.ddpData = this.$parent.ddpMR
            data.dbData = this.$parent.dbMR
            data.dpeData = this.$parent.dpeMR
            data.di_last_step = this.stepTab                           

            // console.log(this.$children[0].$children[0].$children[0].$children[5])

            if(this.stepTab == 1){
                this.$children[0].$children[0].$children[5].$children[0].$refs['VFormInfo'].validate().then(success => {
                    if(success){
                        this.doPost(data)
                    }
                })
            }else if(this.stepTab == 2){
                this.$children[0].$children[0].$children[6].$children[0].$refs['VFormDokumen'].validate().then(success => {
                    if(success){
                        this.doPost(data)
                    }
                })
            }else if(this.stepTab == 3){
                this.$children[0].$children[0].$children[7].$children[0].$refs['VFormPenjamin'].validate().then(success => {
                    if(success){
                        this.doPost(data)
                    }
                })
            }else if(this.stepTab == 4){
                this.$children[0].$children[0].$children[8].$children[0].$refs['VFormRekening'].validate().then(success => {
                    if(success){
                        this.doPost(data)
                    }
                })
            }else if(this.stepTab == 5){
                this.$children[0].$children[0].$children[9].$children[0].$refs['VFormStatus'].validate().then(success => {
                    if(success){
                        this.doPost(data)
                    }
                })
            }else{
                console.log("no action")
            }
            
            
            
        },
        doPost(data){
            this.$parent.loadingOverlay = true           
            return Gen.apiRest('/do/BoDebiturIndividual', {data: data}, 'POST').then(res=>{
                let data = res.data
                if(data.status == 'success'){
                    this.$parent.doSetAlertForm(data)
                    this.$router.push({name:this.$route.name})
                    this.row = {}
                }
                this.$parent.loadingOverlay = false
            }).catch(err=>{
                if(err){
                    err.statusType = err.status
                    err.status = "error"
                    err.title = err.response?.data?.title ? err.response?.data?.title : err.response?.data?.message
                    err.message = err.response?.data?.message                
                    err.messageError = err.message
                }
                this.$parent.doSetAlertForm(err)
                this.$parent.loadingOverlay = false
            })
        }
    },
    computed: {
        stepValidate(){
            return {
            disabled:false,
            validate:true,
            refs:this.$refs
            }
        }
    },
    // mounted(){
    //     this.loadingOverlay = true
    //     setTimeout(() => {
    //         this.stepTab = this.row.di_last_step || 1

    //         this.loadingOverlay = false
    //      }, 700)
    // },
    // watch: {
    //     $route(){
    //        this.loadingOverlay = true
    //         setTimeout(() => {
    //             this.stepTab = this.row.di_last_step || 1

    //             this.loadingOverlay = false
    //         }, 700) 
    //     }
    // }
}
</script>