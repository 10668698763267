<template>    
    <fieldset>
        <validation-observer
            ref="VFormInfo"
        >
            <div class="row">
                <div class="col-md-12">
                    <div class="wrap_wizard_add">
                        <div class="row">
                            <div class="col-md-6">
                                <div class="form-group">
                                    <label>Jenis
                                        Identitas</label>
                                    <v-select placeholder="Pilih Salah Satu" v-model="isParent.row.di_jenis_identitas" :options="Config.mr.IdenPengurus" label="text" :clearable="true" :reduce="v=>v.value"></v-select>
                                </div>
                            </div>
                            <div class="col-md-6">
                                <div class="form-group">
                                    <label>Nomor
                                        Identitas</label>
                                    <input type="text" v-model="isParent.row.di_nomor_identitas" name="name" class="form-control" placeholder="Nomor Identitas">

                                    <VValidate 
                                        name="Nomor Identitas" 
                                        v-model="isParent.row.di_nomor_identitas" 
                                        :rules="{regex:/^[^\s]+(\s+[^\s]+)*$/, min:3, max: 60}"
                                    />
                                </div>
                            </div>
                            <div class="col-md-4">
                                <div class="form-group">
                                    <label>Sapaan <span class="text-danger">*</span></label>                                    
                                    <v-select placeholder="Pilih Sapaan" v-model="isParent.row.di_sapaan" :options="Config.mr.mrSapaan" label="text" :clearable="true" :reduce="v=>v.value"></v-select>

                                    <VValidate 
                                        name="Sapaan" 
                                        v-model="isParent.row.di_sapaan" 
                                        :rules="{required: 1}"
                                    />
                                </div>
                            </div>

                            <div class="col-md-4">
                                <div class="form-group">
                                    <label> Nama Sesuai Identitas <span class="text-danger">*</span></label>
                                    <input type="text" v-model="isParent.row.di_fullname" name="name" class="form-control" placeholder="cth: Anggraeni">

                                    <VValidate 
                                        name="Nama Identitas" 
                                        v-model="isParent.row.di_fullname" 
                                        :rules="{required: 1, min: 3, max: 128}"
                                    />
                                </div>
                            </div>
                            <div class="col-md-4">
                                <div class="form-group">
                                    <label>Pendidikan</label>
                                    <v-select placeholder="Pilih Salah Satu" v-model="isParent.row.di_pendidikan" :options="isParent.$parent.mrEducation" label="text" :clearable="true" :reduce="v=>v.value"></v-select>
                                </div>
                            </div>
                            <div class="col-md-6">
                                <div class="form-group">
                                    <label>Tempat
                                        Lahir</label>
                                    <input type="text" v-model="isParent.row.di_tempat_lahir" name="name" class="form-control" placeholder="cth: Bekasi">

                                    <VValidate 
                                        name="Tempat Lahir" 
                                        v-model="isParent.row.di_tempat_lahir" 
                                        :rules="{min: 3, max: 128}"
                                    />
                                </div>
                            </div>

                            <div class="col-md-6">
                                <div class="form-group">
                                    <label>Tanggal
                                        Lahir</label>
                                    <div class="input-group mb-3 flex-nowrap">
                                        <datepicker input-class="form-control transparent" placeholder="Tanggal Akta Pendirian"
                                            class="my-datepicker w-100" calendar-class="my-datepicker_calendar" v-model="isParent.row.di_tanggal_lahir"
                                        >
                                        </datepicker>
                                        <div class="input-group-append calendar-group">
                                        <span class="input-group-text" id="basic-addon2"><i class="icon-calendar"></i></span>
                                        </div>
                                    </div>  
                                </div>
                            </div>
                            <div class="col-md-6">
                                <div class="form-group">
                                    <label>No NPWP</label>
                                    <input type="text" v-model="isParent.row.di_npwp" name="name" class="form-control" placeholder="09.254.294.3-407.000" data-mask="09.254.294.3-407.000">

                                    <VValidate 
                                        name="Tempat Lahir" 
                                        v-model="isParent.row.di_npwp" 
                                        :rules="{min: 3, max: 64}"
                                    />
                                </div>
                            </div>
                            <div class="col-md-6">
                                <div class="result_tab">
                                    <label>File NPWP </label>
                                    <Uploader isDocument v-model="isParent.row.di_npwp_file" type="npwp"/>
                                </div>
                            </div>
                            <!--
                            <div class="col-md-4">
                                <div class="form-group">
                                    <label for="">Nomor Debitur</label>
                                    <input type="text" v-model="isParent.row.di_no_debitur"  class="form-control">
                                    <VValidate 
                                        name="Nomor Debitur" 
                                        v-model="isParent.row.di_no_debitur" 
                                        :rules="{regex:/^[^\s]+(\s+[^\s]+)*$/, min:3, max: 60}"
                                    />
                                </div>
                            </div>
                            -->
                            <div class="col-md-4" v-if="!user.isIDOfficer">
                                <div class="form-group">
                                    <label for="">Pilih ID Officer <small class="txt_mandatory">*</small></label>
                                    <v-select @search="searchIDOfficer($event)" @open="clearIDOfficer" v-model="isParent.row.di_id_officer" placeholder="Pilih ID Officer" :options="isParent.$parent.mrIDOfficer" label="text" :clearable="true" :reduce="v=>v.value" :disabled="isParent.$parent.pageSlug !== 'add'">
                                        <template v-slot:no-options="{ search, searching }">
                                            <template v-if="searching && search.length > 2">
                                            Tidak ada hasil untuk <em>{{ search }}</em
                                                >.
                                            </template>
                                            <span v-else style="opacity: 0.5">Input 3 karakter untuk mencari ID Officer.</span>
                                        </template>
                                    </v-select>
                                    <VValidate 
                                        name="ID Officer" 
                                        v-model="isParent.row.di_id_officer" 
                                        :rules="{required: 1}"
                                    />
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>

            <div class="row">
                <div class="col-md-12">
                    <div class="heading_line">
                        <h4>Informasi Pekerjaan</h4>
                    </div>
                </div>
            </div>

            <div class="row">
                <div class="col-md-4">
                    <div class="form-group">
                        <label>Bidang
                            Usaha</label>
                        <v-select placeholder="Pilih Bidang Usaha" v-model="isParent.row.di_bu" @open="clearBU" @search="searchBU($event)" :options="isParent.$parent.mrBU" label="text" :clearable="true" :reduce="v=>v.value">
                            <template v-slot:no-options="{ search, searching }">
                                <template v-if="searching && search.length > 2">
                                    Tidak ada hasil untuk <em>{{ search }}</em
                                    >.
                                </template>
                                <span v-else style="opacity: 0.5">Masukkan 3 karakter untuk mencari Badan Usaha.</span>
                            </template>
                        </v-select>
                    </div>
                </div>

                <div class="col-md-4">
                    <div class="form-group">
                        <label> Tempat Bekerja</label>
                        <input type="text" v-model="isParent.row.di_tempat_bekerja" name="name" class="form-control" placeholder="e.g Jakarta">

                        <VValidate 
                            name="Tempat Bekerja" 
                            v-model="isParent.row.di_tempat_bekerja" 
                            :rules="{min: 3, max: 128}"
                        />
                    </div>
                </div>
                <div class="col-md-4">
                    <div class="form-group">
                        <label> Alamat Tempat Bekerja</label>
                        <textarea rows="3" v-model="isParent.row.di_alamat_tempat_bekerja" cols="3" class="form-control" placeholder="Alamat Lengkap"></textarea>

                        <VValidate 
                            name="Alamat Tempat Bekerja" 
                            v-model="isParent.row.di_alamat_tempat_bekerja"
                            :rules="{min: 3, max: 128}"
                        />
                    </div>
                </div>
                <div class="col-md-4">
                    <div class="form-group">
                        <label>Penghasilan Kotor Per Tahun
                        </label>
                        <div class="input-group">
                            <span class="input-group-prepend">
                                <span class="input-group-text">Rp</span>
                            </span>
                            <b-form-input :formatter="value => parseInt((value || '0').replace(/[.,]/g, '')).toLocaleString('id-ID')" type="text" class="form-control" placeholder="500.0000" v-model="isParent.row.di_penghasilan_kotor_year">
                            </b-form-input>
                        </div>

                        <VValidate 
                            name="Penghasilan Kotor Per Tahun" 
                            v-model="isParent.row.di_penghasilan_kotor_year" 
                            :rules="{regex:/^[0-9\.]+$/}" 
                        />
                    </div>
                </div>

                <div class="col-md-4">
                    <div class="form-group">
                        <label>Sumber Penghasilan</label>
                        <v-select placeholder="Pilih Salah Satu" v-model="isParent.row.di_kode_penghasilan" :options="Config.mr.incomeSrcStatus" label="text" :clearable="true" :reduce="v=>v.value"></v-select>
                    </div>
                </div>
                <div class="col-md-3">
                    <div class="form-group">
                        <label> Jumlah Tanggungan</label>
                        <input type="number" v-model="isParent.row.di_jml_tanggungan" name="name" class="form-control" placeholder="cth: 2" min="0">

                        <VValidate 
                            name="Jumlah Tanggungan" 
                            v-model="isParent.row.di_jml_tanggungan"
                            :rules="{numeric: 1}"
                        />
                    </div>
                </div>

                <div class="col-md-3">
                    <div class="form-group">
                        <label>Golongan Debitur</label>
                        <v-select placeholder="Pilih Golongan Debitur" @open="clearGD" v-model="isParent.row.di_gol_debitur" @search="searchGD" :options="isParent.$parent.mrGolDeb" label="text" :clearable="true" :reduce="v=>v.value">\
                            <template v-slot:no-options="{ search, searching }">
                                <template v-if="searching && search.length > 2">
                                    Tidak ada hasil untuk <em>{{ search }}</em
                                    >.
                                </template>
                                <span v-else style="opacity: 0.5">Masukkan 3 karakter untuk mencari Golongan Debitur.</span>
                            </template>
                        </v-select>
                    </div>
                </div>

                <div class="col-md-3">
                    <div class="form-group">
                        <label>Status Perkawinan</label>
                        <v-select placeholder="Pilih Salah Satu" v-model="isParent.row.di_sts_kawin" :options="Config.mr.weddingStatus" label="text" :clearable="true" :reduce="v=>v.value"></v-select>
                    </div>
                </div>

                <div class="col-md-3">
                    <div class="form-group">
                        <label> Nama Gadis Ibu Kandung
                        </label>
                        <input type="text" v-model="isParent.row.di_fullname_ibu" name="name" class="form-control" placeholder="cth: Gladis">

                        <VValidate 
                            name="Nama Gadis Ibu Kandung" 
                            v-model="isParent.row.di_fullname_ibu" 
                            :rules="{min: 3, max: 128}"
                        />
                    </div>
                </div>
            </div>

            <div class="heading_line">
                <h4>Identitas Pasangan</h4>
            </div>
            <div class="row">
                <div class="col-md-3">
                    <div class="form-group">
                        <label> Nomor Identitas Pasangan</label>
                        <input type="number" v-model="isParent.row.di_no_identitas_pasangan" name="name" class="form-control" placeholder="cth: 1329389283933">

                        <VValidate 
                            name="Nomor Identitas Pasangan" 
                            v-model="isParent.row.di_no_identitas_pasangan" 
                            :rules="{numeric: 1, min: 3, max: 16}"
                        />
                    </div>
                </div>

                <div class="col-md-3">
                    <div class="form-group">
                        <label> Nama Pasangan
                        </label>
                        <input type="text" v-model="isParent.row.di_fullname_pasangan" name="name" class="form-control" placeholder="cth: Maria">

                        <VValidate 
                            name="Nama Pasangan" 
                            v-model="isParent.row.di_fullname_pasangan" 
                            :rules="{min: 3, max: 128}"
                        />
                    </div>
                </div>

                <div class="col-md-3">
                    <div class="form-group">
                        <label>Tanggal
                            Lahir Pasangan</label>
                        <div class="input-group mb-3">
                            <datepicker input-class="form-control transparent" placeholder="Tanggal Lahir Pasangan"
                            class="my-datepicker" calendar-class="my-datepicker_calendar" v-model="isParent.row.di_tanggal_lahir_pasangan"
                            >
                            </datepicker>
                            <div class="input-group-append calendar-group">
                            <span class="input-group-text" id="basic-addon2"><i class="icon-calendar"></i></span>
                            </div>
                        </div>  
                    </div>
                </div>
            </div>

            <div class="heading_line">
                <h4>Informasi Kontak</h4>
            </div>
            <div class="row">
                <div class="col-md-4">
                    <div class="form-group">
                        <label>No Telepon </label>
                        <div class="input-group">
                            <span class="input-group-prepend">
                                <span class="input-group-text">+62</span>
                            </span>
                            <input type="text" class="form-control" placeholder="+99-999-9999-9999" data-mask="+99-999-9999-9999" v-model="isParent.row.di_kontak_telp">

                        </div>
                        <VValidate 
                            name="No Telepon" 
                            v-model="isParent.row.di_kontak_telp" 
                            :rules="{numeric: 1, min:3, max: 13}"
                        />
                    </div>
                </div>
                <div class="col-md-4">
                    <div class="form-group">
                        <label>No Telepon Selular </label>
                        <div class="input-group">
                            <span class="input-group-prepend">
                                <span class="input-group-text">+62</span>
                            </span>
                            <input type="text" class="form-control" placeholder="+99-999-9999-9999" data-mask="+99-999-9999-9999" v-model="isParent.row.di_kontak_cell">
                        </div>

                        <VValidate 
                            name="No Telepon Selular" 
                            v-model="isParent.row.di_kontak_cell" 
                            :rules="{numeric: 1, min:3, max: 13}"
                        />
                    </div>
                </div>
                <div class="col-md-4">
                    <div class="form-group">
                        <label>Email</label>
                        <input type="email" name="email" class="form-control" placeholder="yourcompany@email.com" v-model="isParent.row.di_kontak_email">

                        <VValidate 
                            name="Email" 
                            v-model="isParent.row.di_kontak_email" 
                            :rules="{email: 1, min:3, max: 128}"
                        />
                    </div>
                </div>
            </div>

            <div class="row">
                <div class="col-md-12">
                    <div class="heading_line">
                        <h4>Alamat sesuai KTP</h4>
                    </div>
                </div>
            </div>
            <div class="row">
                <div class="col-md-6">
                    <label>Provinsi</label>
                    <div class="form-group">
                        <v-select placeholder="Pilih Provinsi" @input="doInputKab()" v-model="isParent.row.di_prov" :options="isParent.$parent.mrProvince"  label="text" :clearable="true" :reduce="v=>v.value"></v-select>
                    </div>
                </div>
                <div class="col-md-6">
                    <label>Kota / Kabupaten</label>
                    <div class="form-group">
                        <v-select placeholder="Pilih Kab/Kota" :options="isParent.$parent.mrCity" label="text" @input="doInputKec()" v-model="isParent.row.di_city" :clearable="true" :reduce="v=>v.value"></v-select>
                    </div>
                </div>

            </div>
            <div class="row">
                <div class="col-md-6">
                    <label>Kecamatan</label>
                    <div class="form-group">
                        <v-select placeholder="Pilih Kecamatan" :options="isParent.$parent.mrDistrict" label="text" @input="doInputKel()" v-model="isParent.row.di_kec" :clearable="true" :reduce="v=>v.value"></v-select>
                    </div>
                </div>
                <div class="col-md-6">
                    <label>Kelurahan</label>
                    <div class="form-group">
                        <v-select placeholder="Pilih Kelurahan" :options="isParent.$parent.mrSDistrict" label="text" v-model="isParent.row.di_kel" :clearable="true" :reduce="v=>v.value"></v-select>
                    </div>
                </div>                

            </div>
            <div class="row">
                <div class="col-md-3">
                    <label>Kode Pos</label>
                    <div class="form-group">
                        <v-select placeholder="Pilih Kode Pos" :options="isParent.$parent.mrPostalCode" label="text" v-model="isParent.row.di_postal_code" :clearable="true" :reduce="v=>v.value"></v-select>
                    </div>
                </div>

            </div>
            <div class="row">
                <div class="col-md-7">
                    <div class="form-group">
                        <label>Alamat Sesuai KTP</label>
                        <textarea rows="3" cols="3" v-model="isParent.row.di_alamat_domisili" class="form-control" placeholder="Alamat Lengkap"></textarea>

                        <VValidate 
                            name="Alamat KTP" 
                            v-model="isParent.row.di_alamat_domisili"
                            :rules="{regex:/^[^\s]+(\s+[^\s]+)*$/, min:3, max: 256}"
                        />
                    </div>
                </div>

            </div>
            
            <div class="form-check form-check-inline">
                <label class="d-flex align-items-center">
                    <input class="form-check-input mt-0" type="checkbox" value="checked" id="flexCheckChecked" v-model="isParent.row.di_equal_ktp">
                    Alamat domisili sama dengan KTP?
                </label>
            </div>
            <template v-if="!isParent.row.di_equal_ktp">
                <div class="row">
                    <div class="col-md-12">
                        <div class="heading_line">
                            <h4>Alamat sesuai Domisili</h4>
                        </div>
                    </div>
                </div>
                
                <div class="row">
                    <div class="col-md-6">
                        <label>Provinsi</label>
                        <div class="form-group">
                            <v-select placeholder="Pilih Provinsi" @input="doInputKabKTP()" v-model="isParent.row.di_prov_ktp" :options="isParent.$parent.mrProvinceKTP"  label="text" :clearable="true" :reduce="v=>v.value"></v-select>
                        </div>
                    </div>
                    <div class="col-md-6">
                        <label>Kota / Kabupaten</label>
                        <div class="form-group">
                            <v-select placeholder="Pilih Kab/Kota" :options="isParent.$parent.mrCityKTP" label="text" @input="doInputKecKTP()" v-model="isParent.row.di_city_ktp" :clearable="true" :reduce="v=>v.value"></v-select>
                        </div>
                    </div>

                </div>
                <div class="row">
                    <div class="col-md-6">
                        <label>Kecamatan</label>
                        <div class="form-group">
                            <v-select placeholder="Pilih Kecamatan" :options="isParent.$parent.mrDistrictKTP" label="text" @input="doInputKelKTP()" v-model="isParent.row.di_kec_ktp" :clearable="true" :reduce="v=>v.value"></v-select>
                        </div>
                    </div>
                    <div class="col-md-6">
                        <label>Kelurahan</label>
                        <div class="form-group">
                            <v-select placeholder="Pilih Kelurahan" :options="isParent.$parent.mrSDistrictKTP" label="text" v-model="isParent.row.di_kel_ktp" :clearable="true" :reduce="v=>v.value"></v-select>
                        </div>
                    </div>                

                </div>
                <div class="row">
                    <div class="col-md-3">
                        <label>Kode Pos</label>
                        <div class="form-group">
                            <v-select placeholder="Pilih Kode Pos" :options="isParent.$parent.mrPostalCodeKTP" label="text" v-model="isParent.row.di_postal_code_ktp" :clearable="true" :reduce="v=>v.value"></v-select>
                        </div>
                    </div>

                </div>
                <div class="row mt-3">
                    <div class="col-md-7">
                        <div class="form-group">
                            <label>Alamat Domisli</label>
                            <textarea rows="3" cols="3" v-model="isParent.row.di_alamat_ktp" class="form-control" placeholder="Alamat Lengkap"></textarea>

                            <VValidate 
                                name="Alamat Domisili" 
                                v-model="isParent.row.di_alamat_ktp"
                                :rules="{regex:/^[^\s]+(\s+[^\s]+)*$/, min:3, max: 256}"
                            />
                        </div>
                    </div>
                </div>
            </template>
        </validation-observer>
    </fieldset>
</template>

<script>
    import GlobalVue from '@/libs/Global.vue'
    import Datepicker from 'vuejs-datepicker'
    import Gen from '@/libs/Gen.js'

    export default {
        extends: GlobalVue,
        props: ['clickedNext', 'currentStep'],
        components:{ Datepicker },
        computed: {
            isParent() { return this.$parent.$parent.$parent.$parent }
        },
        data(){
            return {
                searchBUTxt : ""
            }
        },
        mounted() {
            console.log(this.isParent)
            setTimeout(() => {                 
             }, 700)
        },
        methods: {
            clearCodeBU(){
                this.isParent.row.di_bentuk_bu = null
                this.isParent.$parent.mrCodeBU = []
            },
            searchCodeBU(e){
                if(e.length > 2){
                    Gen.apiRest('/do/BoDebiturIndividual', {data: {type:'select-code-bu', search: e}}, 'POST').then(res=>{
                        this.isParent.$parent.mrCodeBU = res.data.data
                    })
                }
            },
            clearBU(){
                this.isParent.row.di_bu = null
                this.isParent.$parent.mrBU = []
            },
            searchBU(e){
                if(e.length > 2){
                    Gen.apiRest('/do/BoDebiturIndividual', {data: {type:'select-bu', search: e}}, 'POST').then(res=>{
                        this.isParent.$parent.mrBU = res.data.data
                    })
                }
            },
            searchJU(e){
                if(e.length > 2){
                    Gen.apiRest('/do/BoDebiturIndividual', {data: {type:'select-ju', search: e}}, 'POST').then(res=>{
                        this.isParent.$parent.mrJU = res.data.data
                    })
                }else{
                    this.isParent.$parent.mrJU = []
                }
            },
            clearGD(){
                this.isParent.row.di_gol_debitur = null
                this.isParent.$parent.mrGolDeb = []
            },
            searchGD(e){
                if(e.length > 2){
                    Gen.apiRest('/do/BoDebiturIndividual', {data: {type:'select-gol-deb', search: e}}, 'POST').then(res=>{
                        this.isParent.$parent.mrGolDeb = res.data.data
                    })
                }
            },
            searchCity(e){
                Gen.apiRest('/do/BoDebiturIndividual', {data: {type:'select-city', search: e, prov: this.isParent.row.di_prov}}, 'POST').then(res=>{
                    this.isParent.$parent.mrCity = res.data.data
                })
            },
            
            doInputKab(){
                this.isParent.$parent.mrCity = []
                this.isParent.$parent.mrDistrict = []
                this.isParent.$parent.mrSDistrict = []
                this.isParent.$parent.mrPostalCode = []

                this.isParent.row.di_city = null
                this.isParent.row.di_kec = null
                this.isParent.row.di_kel = null
                this.isParent.row.di_postal_code = null

                Gen.apiRest('/do/BoDebiturIndividual', {data: {type:'select-city', prov: this.isParent.row.di_prov}}, 'POST').then(res=>{
                    this.isParent.$parent.mrCity = res.data.data
                })
                
            },
            doInputKec(){
                this.isParent.$parent.mrDistrict = []
                this.isParent.$parent.mrSDistrict = []
                this.isParent.$parent.mrPostalCode = []
                
                this.isParent.row.di_kec = null
                this.isParent.row.di_kel = null
                this.isParent.row.di_postal_code = null

                Gen.apiRest('/do/BoDebiturIndividual', {data: {type:'select-district', prov: this.isParent.row.di_prov, city: this.isParent.row.di_city}}, 'POST').then(res=>{
                    this.isParent.$parent.mrDistrict = res.data.data
                })
            },
            doInputKel(){
                this.isParent.$parent.mrSDistrict = []
                this.isParent.$parent.mrPostalCode = []
                
                this.isParent.row.di_kel = null
                this.isParent.row.di_postal_code = null
                
                Gen.apiRest('/do/BoDebiturIndividual', {data: {type:'select-sub-district', prov: this.isParent.row.di_prov, city: this.isParent.row.di_city, district: this.isParent.row.di_kec}}, 'POST').then(res=>{
                    this.isParent.$parent.mrSDistrict = res.data.data
                })
                
                Gen.apiRest('/do/BoDebiturIndividual', {data: {type:'select-postal-code', prov: this.isParent.row.di_prov, city: this.isParent.row.di_city, district: this.isParent.row.di_kec}}, 'POST').then(res=>{
                    this.isParent.$parent.mrPostalCode = res.data.data
                })
            },

            doInputKabKTP(){
                this.isParent.$parent.mrCityKTP = []
                this.isParent.$parent.mrDistrictKTP = []
                this.isParent.$parent.mrSDistrictKTP = []
                this.isParent.$parent.mrPostalCodeKTP = []

                this.isParent.row.di_city_ktp = null
                this.isParent.row.di_kec_ktp = null
                this.isParent.row.di_kel_ktp = null
                this.isParent.row.di_postal_code_ktp = null

                Gen.apiRest('/do/BoDebiturIndividual', {data: {type:'select-city', prov: this.isParent.row.di_prov_ktp}}, 'POST').then(res=>{
                    this.isParent.$parent.mrCityKTP = res.data.data
                })
                
            },
            doInputKecKTP(){
                this.isParent.$parent.mrDistrictKTP = []
                this.isParent.$parent.mrSDistrictKTP = []
                this.isParent.$parent.mrPostalCodeKTP = []
                
                this.isParent.row.di_kec_ktp = null
                this.isParent.row.di_kel_ktp = null
                this.isParent.row.di_postal_code_ktp = null

                Gen.apiRest('/do/BoDebiturIndividual', {data: {type:'select-district', prov: this.isParent.row.di_prov_ktp, city: this.isParent.row.di_city_ktp}}, 'POST').then(res=>{
                    this.isParent.$parent.mrDistrictKTP = res.data.data
                })
            },
            doInputKelKTP(){
                this.isParent.$parent.mrSDistrictKTP = []
                this.isParent.$parent.mrPostalCodeKTP = []
                
                this.isParent.row.di_kel_ktp = null
                this.isParent.row.di_postal_code_ktp = null
                
                Gen.apiRest('/do/BoDebiturIndividual', {data: {type:'select-sub-district', prov: this.isParent.row.di_prov_ktp, city: this.isParent.row.di_city_ktp, district: this.isParent.row.di_kec_ktp}}, 'POST').then(res=>{
                    this.isParent.$parent.mrSDistrictKTP = res.data.data
                })
                
                Gen.apiRest('/do/BoDebiturIndividual', {data: {type:'select-postal-code', prov: this.isParent.row.di_prov_ktp, city: this.isParent.row.di_city_ktp, district: this.isParent.row.di_kec_ktp}}, 'POST').then(res=>{
                    this.isParent.$parent.mrPostalCodeKTP = res.data.data
                })
            },

            clearIDOfficer(){
                this.isParent.$parent.mrIDOfficer = []
                this.isParent.row.di_id_officer = null
            },
            searchIDOfficer(e){
                if(e.length > 2){
                    Gen.apiRest('/do/'+this.modulePage, {data: {type:'select-id-officer', search: e}}, 'POST').then(res=>{
                        this.isParent.$parent.mrIDOfficer = res.data.data
                    })
                }
            },
        },
    }
</script>