<template>
<fragment v-if="selected">
  <h6 :id="'steps-uid-d-'+option" tabindex="-1" class="title current">
    <slot name="title"></slot>
  </h6>
  <fieldset :id="'steps-uid-c-'+option" role="tabpanel" v-bind:aria-labelledby="'steps-uid-d-'+option" :class="classStep" v-bind:aria-hidden="!selected">
    <slot name="fieldset"></slot>
  </fieldset>
</fragment>
<fragment v-else></fragment>
</template>

<script>
import { Fragment } from 'vue-fragment'

export default {
  name:"VStepContent",
  components:{Fragment},
  props:{
    step:{
      default:1,
      type:Number
    },
    option:{
      default:1,
      type:Number
    }
  },
  data(){
    return {
      opened:false
    }
  },
  computed:{
    classStep(){
      var c = "body"
      if(this.step == this.option)
        c += "current "        
      return c
    },
    selected(){
      var c = this.classStep
      return c.includes("current")
    }
  },
  methods:{
  },
  watch:{
    step(e){
      if(e == this.option)
        this.opened = true
    }
  }
}
</script>